import React from 'react'
import { UserProvider } from './sub-contexts/userContext'
import { ThemeProvider } from './sub-contexts/themeContext'
import { ModalProvider } from './sub-contexts/modalContext'
import { ResizeProvider } from './sub-contexts/resize-context'
import { HeaderProvider } from './sub-contexts/headerObserverContext'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

/*
    GlobalDailybreakContext represents the broader shared moving parts that the site needs, e.g.:
    - is this user a guest or are they logged in?
    - award the user points
    - a guest attempted to perform an action that requires an account - tell the site to pop some form of notifcation

    this this is global, try to keep it lean if possible

*/

const GlobalDailybreakContext = ({ children }) => {
  return (
    <ThemeProvider>
      <ModalProvider>
        <UserProvider>
          <ResizeProvider>
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.GOOGLE_RECAPTCHA_V3_SITE_KEY}
            >
              <HeaderProvider>{children}</HeaderProvider>
            </GoogleReCaptchaProvider>
          </ResizeProvider>
        </UserProvider>
      </ModalProvider>
    </ThemeProvider>
  )
}

export default GlobalDailybreakContext
